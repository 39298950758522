import * as React from "react"
import Layout from "../components/common/Layout"
import ApplyBox from "../components/common/formboxes/ApplyBox"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/career.css"
import InfoBoxes from "../components/common/infoboxes/InfoBoxes"
import Meta from "../components/common/Meta"
import Main from "../components/common/Main"
import PageIntro from "../components/common/intro/PageIntro"
import styled from "styled-components"
import BenefitsSection from "../components/career/BenefitsSection"
import OurRecruitmentProcess from "../components/career/OurRecruitmentProcess"
import { RebaseDigest } from "../components/common/downloadSection/RebaseDigest"

const ImageContainer = styled.div`
  width: fit-content;
  max-width: min(1031px, 100% - 2.5rem);
  margin-bottom: 5.5rem;
  margin-inline: auto;
  position: relative;
  margin-top: 2rem;
  > * {
    margin-left: -7%;
  }
  @media (min-width: 64em) {
    margin-top: 5.5rem;
    margin-bottom: 7.5rem;
    > * {
      margin-left: 0;
    }
  }
`

export const Head = () => (
  <Meta
    title="Join Rebase - Great Salaries, Freedom and Best Projects"
    description="Join Rebase for top salaries, flexibility, and support. Enjoy premium healthcare and a flat structure. Careers in software, cloud, data, QA, and agility. Apply!"
  />
)

const CareerPage = () => {
  const title = [["Re / Think", "Your Job"]]

  return (
    <Layout>
      <Main>
        <PageIntro
          title={title}
          subheading="We offer the freedom to choose your projects, work schedule, and base of
            operations — including being remote. All with one simple and unbeatable
            salary model. With us, you can be your boss without the risk, stress, or
            administrative burden."
        />
        <ImageContainer>
          <StaticImage src="../images/career/career_group.jpg" alt="" />
        </ImageContainer>
        <BenefitsSection />
        <OurRecruitmentProcess />
        <RebaseDigest />
      </Main>
      <InfoBoxes
        content={[
          { number: "356", extra: "", content: "Available projects (10/2024)" },
          { number: 8.6, extra: "%", content: "Employee turnover (2023)" },
          {
            number: 94.6,
            extra: "%",
            content: "Billable rate (January - October 2024)",
          },
        ]}
      />
      <ApplyBox />
    </Layout>
  )
}

export default CareerPage
